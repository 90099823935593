import cookieService from 'services/cookies'

declare let window: any

const setupRefvisCookie = (allowedParamsIds: string[]): void => {
  const params = new URLSearchParams(window.location.search)
  // Extra URL params for media partners
  // (uap === bankierid)
  const partnerParams = ['uap', 'at_gd', 'xchangerId']

  const refvis = [...allowedParamsIds, ...partnerParams].reduce((acc, curr) => {
    const param = params.get(curr)
    return !param
      ? acc
      : curr.includes('utm') && !acc
      ? `${acc}${param}`
      : curr.includes('utm')
      ? `${acc}_${param}`
      : `${acc}&${curr}=${param}`
  }, '')

  if (refvis) {
    cookieService.setItem('refvis', refvis, 'Lax', cookieService.maximalCookieTTL)
  }
}

function handleRefererCode(): void {
  const params = new URLSearchParams(window.location.search)
  const refererCode = params.get('refererCode')
  if (refererCode) {
    cookieService.setItem('refererCode', refererCode, 'Lax', cookieService.maximalCookieTTL)
  }
}

function setupCookies(): void {
  // Allowed URL params that are stored as cookie.
  const allowedParamsIds = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'utm_content']
  cookieService.setupCookiesFromURL(allowedParamsIds)
  cookieService.setupCookiesFromClient()
  setupRefvisCookie(allowedParamsIds)
  handleRefererCode()
}

setupCookies()
